<template>
  <div class="alumni">
    <v-card
      style="border-radius: 0"
    >
      <v-card-title>
        Alumni
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search alumni"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="names"
        :search="search"
        :footer-props="rowsPerPageItems"
        :items-per-page="pagination">
        <template v-slot:[`item.emailPresent`]="{ item }">
          <span>{{ item.emailPresent === true ? '✅' : '❌' }}</span>
        </template>
      ></v-data-table>
    </v-card>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'Alumni',
  data () {
    return {
        headers: [
        { text: 'Name', value: 'name' },
        { text: 'Registered Email', value: 'emailPresent' },
      ],
      names: [],
      search: '',
      rowsPerPageItems: {"items-per-page-options": [-1, 20, 100]},
    }
  },
  mounted: function () {
    this.updateTable();

    setInterval(function () {
      this.updateTable();
    }.bind(this), 10000);
  },
  methods: {
    updateTable() {
      axios({
        method: 'get',
        url: 'https://api.shhsclassof2010.com/alumnis', // this.getApiURL() + '/alumni',
        headers: {'Access-Control-Allow-Origin': '*'},
      }).then(response => this.names = response.data)
    },
    getApiURL(envsubstValue='$API_URL') {
      return '$' + this.preventEnvsubstReplacement() !== envsubstValue ? envsubstValue : 'http://127.0.0.1:8080'
    },
    preventEnvsubstReplacement(obfuscation='API_URL') {
      return obfuscation
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
